import ApiService from './api.service';

const serviceURL = 'addition_cost';
const AddCostDetailService = {
	/**
	 * Acquire addcost details by quoteID
	 * @param {Integer} quoteId
	 * @returns {Array} addCostDetails
	 */
	get(quoteId) {
		return ApiService.get(`${serviceURL}`, `${quoteId}`);
	},

	/**
	 * Post bulk AddCost Detail records
	 * @param {Object} body {doc_content: [estimateDetails Array]}
	 * @returns {Object} Success message
	 */
	postBulk(body) {
		return ApiService.post(`${serviceURL}/bulk`, body);
	},

	/**
	 * Post AddCost Detail record
	 * @param {Object} body {doc_content: {adcostDetails Object}}
	 * @returns {Object} Success message
	 */
	post(body) {
		let content = {
			doc_content: body,
		};
		return ApiService.post(`${serviceURL}/`, content);
	},

	/**
	 * Update AddCost Detail record
	 * @param {Object} body {doc_content: {estimateDetails Object}}
	 * @returns {Object} Success message
	 */
	update(id, body) {
		let content = {
			doc_content: body,
		};
		return ApiService.put(`${serviceURL}/${id}`, content);
	},

	/**
	 * Update bulk AddCost Detail record
	 * @param {Object} body {doc_content: [estimateDetails Array]}
	 * @returns {Object} Success message
	 */
	updateBulk(body) {
		return ApiService.put(`${serviceURL}/bulk`, body);
	},

	/**
	 * Delete bulk AddCost Detail records
	 * @param {Object} body {doc_content: [estimateDetailIds Array]}
	 * @returns {Object} Success message
	 */
	deleteBulk(body) {
		return ApiService.deleteBulk(`${serviceURL}/bulk`, body);
	},
};

export default AddCostDetailService;
