import ApiService from './api.service';

const serviceURL = 'estimate_particular';
const EstimateParticularService = {
	/**
	 * Acquire estimateParticular by project id
	 * @param {Integer} projectId
	 * @returns {Object} estimateParticular object
	 */
	get(projectId) {
		return ApiService.get(
			`${serviceURL}/project`,
			`${projectId}/children?withParent=true`
		);
	},

	/**
	 * Acquire estimateParticular by estimateParticular id
	 * @param {Integer} estimateParticularId
	 * @returns {Object} estimateParticular object
	 */
	getById(estimateParticularId) {
		return ApiService.get(
			`${serviceURL}`,
			`${estimateParticularId}/children?withParent=true`
		);
	},

	/**
	 * Acquire all estimateParticular by project id
	 * @param {Integer} projectId
	 * @returns {Array} array of estimateParticulars
	 */
	getAll(projectId) {
		return ApiService.get(`${serviceURL}`, projectId);
	},

	/**
	 * Create new Estimate Particular record
	 * @param {Object} body {doc_content: {estimateParticular Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Update Estimate Particular record
	 * @param {Integer} id Estimate Particular id
	 * @param {Object} body {doc_content: {estimateParticular Object}}
	 * @returns {Object} success message
	 */
	update(id, body) {
		return ApiService.put(`${serviceURL}/${id}`, body);
	},

	/**
	 * confirm estimate particular content
	 * @param {Integer} id: Estimate Particular id
	 * @returns success message
	 */
	confirm(id) {
		return ApiService.put(`${serviceURL}/${id}/confirm`);
	},
};

export default EstimateParticularService;
