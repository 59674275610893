import ApiService from './api.service';

const serviceURL = 'document';
const DocumentService = {
	/**
	 * Acquire delivery by projectID
	 * @param {Integer} projectId
	 * @returns {Object} Schedule details
	 */
	get(projectId) {
		return ApiService.get(`${serviceURL}`, `${projectId}`);
	},

	/**
	 * Update document
	 * @param {Integer} projectId: Project ID
	 * @param {Object} contents: update content
	 * @returns
	 */
	update(projectId, contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.put(`${serviceURL}/${projectId}`, body);
	},
};

export default DocumentService;
