// import dragElement from './NotificationDragFunc';
import { AllowDrag } from '../../directives/HxAllowDrag/allowDrag.directive';
import HxPdfExporter from '@/components/HxPdfExporter/HxPdfExporter.vue';
export default {
	name: 'ReasonModal',
	components: { HxPdfExporter },
	directives: {
		AllowDrag,
	},
	props: {
		/**
		 * @binding Reason Modal title
		 * @example '再見積り依頼の理由'
		 */
		reasonTitle: {
			type: String,
		},
		/**
		 * @binding Reason Modal Id
		 * @example 'estimate-reason-modal'
		 */
		reasonModalId: {
			type: String,
		},
	},
	data() {
		return {
			reasonContent: '',
			disableYesBtn: true,
		};
	},
	watch: {
		reasonContent: {
			handler: function (newVal) {
				if (newVal !== '') {
					this.disableYesBtn = false;
				} else {
					this.disableYesBtn = true;
				}
			},
		},
	},
	methods: {
		onSelectYesClick() {
			if (!this.reasonContent) return;

			this.$emit('on-yes-click', this.reasonContent);
			this.$bvModal.hide(`${this.reasonModalId}`);
			this.reasonContent = '';
		},

		onSelectNoClick() {
			this.$bvModal.hide(`${this.reasonModalId}`);
			this.reasonContent = '';
		},
	},
};
