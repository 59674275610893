import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import NotificationModal from '../../../components/NotificationModal/NotificationModal.vue';
import socketClient from '../../../services/SOCKET';
import HxPdfExporter from '../../../components/HxPdfExporter/HxPdfExporter.vue';
import Modal from '../../../components/Modal/Modal.vue';
import Badge from '../../../components/Badge/StatusBadge.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import ReasonModal from '@/components/ReasonModal/ReasonModal.vue';

import TransferService from '@/services/API/transfer.service';
import EstimateParticularService from '../../../services/API/estimate_particular.service';
import AddCostDetailService from '../../../services/API/add_cost_details.service';
import ScheduleService from '../../../services/API/schedule.service';
import DocumentService from '../../../services/API/document.service';

import moment from 'moment';
import Axios from 'axios';
import { mapState } from 'vuex';

import updateProjectStatusMixin from '@/mixin/updateProjectStatus';
import { sendNotify, toLineMessageTemplate } from '@/utilities';
import CommonConst from '@/constants/CommonConst';
import redDotNotifyMixin from '../../../mixin/redDotNotifyMixin.js';

const ADD_COST_SCREEN_ID = 6;
const SELECTIVE_CONTENT = '「 再提案設定 」を承認しますか？';

const NO_CONTENT_MESSAGE = '再提案の設定を作成中';
const REASON_SENT_CONTENTS = '再検討依頼を送信しました';
const CONFIRM_SENT_CONTENTS = '「 再提案 」に進みます';
const REPROPOSAL_SCREEN_ID = 7;
export default {
	name: 'AddCostScreen',
	mixins: [updateProjectStatusMixin, redDotNotifyMixin],
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	components: {
		ScreenHeader,
		ProjectDeadline,
		NotificationModal,
		HxPdfExporter,
		Modal,
		Badge,
		BottomButton,
		ReasonModal,
	},
	data() {
		return {
			screenIcon: 'addcost-icon',
			screenName: '再提案設定',
			defaultFileName: '',
			selectiveContents: SELECTIVE_CONTENT,
			plainContents: '',
			requirementContents: '',
			companyName: 'ハッピーカフェ',
			originEstimateData: {
				estimateParticular: {},
				estimateDetails: [],
			},
			addCostDetails: [],
			isTemplateShow: false,
			pdfData: {},
			pdfFileKey: '',
			expiration: '600',
			pdfFileUrl: '',
			selectiveModalId: 'addcost-selective-modal',
			plainModalId: 'addcost-plain-modal',
			middleContent: ' 様 ／ ',
			isSearchIconShow: false,
			isRetryReasonShow: false,
			isProposalContentShow: false,
			noContentMessage: NO_CONTENT_MESSAGE,
			badgeType: '',
			badgeContent: '',
			showStatusBadge: false,
			showBottomBtn: false,
			addCostDetailId: '',
			addcostRedeliveryDate: '',
			addcostReproposalDate: '',
			addcostDetailRemark: '',
			addCostStatus: '',
			mailContent: '',

			pdfPreviewer: {},
			mailTitle: '',
			subject: '',
			isSendCreator: false,
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'clientMode',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'preview',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
		]),
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}addcost`
						: `${this.projectInfo['process_client_url']}addcost`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}addcost`
					: `${this.projectInfo['process_client_url']}addcost`,
			});
		},

		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},

	watch: {
		// Calculate amount when consumption_tax_rate and addcostDetails existed
		managementMasterInfo(newVal) {
			if (newVal['consumption_tax_rate'] && this.addCostDetails.length > 0) {
				this.computeAddCostDetailsMoneyAmount(
					newVal['consumption_tax_rate'],
					this.addCostDetails
				);
			}
		},

		addCostStatus(newStatus) {
			// Update notification red-dot base on screen status
			if (newStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.setRedDotNotiy(CommonConst.SCREEN_ID.ADDCOST);
			} else {
				this.removeRedDotNotifyWithSocket(CommonConst.SCREEN_ID.ADDCOST);
			}
		},
	},

	methods: {
		onConfirmNoClick() {
			console.log('no');
		},

		/**
		 * Calculate subtotal of all addCostDetail records
		 * @param {Array} addCostDetails AddCostDetail array
		 * @returns addCostDetailsSubtotal
		 */
		addCostDetailsSubtotal(addCostDetails) {
			if (addCostDetails && addCostDetails.length > 0) {
				return addCostDetails.reduce((acc, addCostDetail) => {
					return acc + Math.trunc(addCostDetail['quantity'] * addCostDetail['unit_price']);
				}, 0);
			}
		},

		/**
		 * Calculate Addcost Details money amount
		 * @param {Integer} consumptionTaxRate ManagementMaster consumption tax rate
		 * @param {Array } addCostDetails AddCostDetail array
		 */
		computeAddCostDetailsMoneyAmount(consumptionTaxRate, addCostDetails) {
			if (consumptionTaxRate && addCostDetails.length > 0) {
				let addCostDetailsSubtotal = this.addCostDetailsSubtotal(addCostDetails);

				let addCostDetailsConsumptionTax =
					(addCostDetailsSubtotal * consumptionTaxRate) / 100;
				let subtotal =
					this.originEstimateData.estimateParticular['subtotal'] +
					addCostDetailsSubtotal;
				let consumptionTax =
					Math.trunc(this.originEstimateData.estimateParticular['consumption_tax'] +
						addCostDetailsConsumptionTax);
				this.originEstimateData.estimateParticular['subtotal'] = subtotal;
				let holdingTax = 0;
				if (this.projectInfo.with_holding_tax == 1) {
					if (subtotal < 1000000) {
						holdingTax = (subtotal * 10.21) / 100;
					} else {
						holdingTax = ((subtotal - 1000000) * 20.42) / 100 + 102100;
					}
				}
				holdingTax = Math.trunc(holdingTax);
				this.originEstimateData.estimateParticular['holding_tax'] = holdingTax
				this.originEstimateData.estimateParticular['consumption_tax'] = consumptionTax;
				this.originEstimateData.estimateParticular['total'] = subtotal + consumptionTax
					+ holdingTax;
			}
		},

		async onConfirmYesClick() {
			this.$store.commit('setIsAppProcessing', true);

			// this.$refs.hxPdfExporter._renderTemplateString();
			this.$refs.hxPdfExporterPurchaseOrder._renderTemplateString();
			let updateDetail = {
				status: CommonConst.SCREEN_STATUS.ACCEPT,
			};

			this.addCostStatus = CommonConst.SCREEN_STATUS.ACCEPT;
			await this._updateAddCostDetails(this.addCostDetailId, updateDetail);
			await ScheduleService.update(this.projectId, {
				delivery_date: this.addcostRedeliveryDate,
			});
			let updateSchedule = {
				...this.schedule,
				delivery_date: this.addcostRedeliveryDate,
			};
			this.$store.commit('setSchedule', updateSchedule);

			socketClient.send('client_accept_addcost', {
				userId: this.userId,
				projectId: this.projectId,
				content: true,
			});

			this.showBottomBtn = false;
			this.plainContents = CONFIRM_SENT_CONTENTS;
			this.$bvModal.show(`${this.plainModalId}`);
			// send mail case 46
			let reProposalDate = moment(this.addcostReproposalDate).format(
				'YYYY年MM月DD日 HH:mm'
			);
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「再提案の詳細」確定`;
			this.mailContent = `「再提案の詳細」が承認されました。\n提案日：${reProposalDate} に\nCo-modeを接続してください。`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「再提案の詳細」確定`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 「再提案の詳細」確定`;
			this.mailContent = `「再提案の詳細」を承認しました。\n提案日：${reProposalDate} に\nCo-modeを接続してください。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 「再提案の詳細」確定`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$store.commit('setIsAppProcessing', false);
		},

		onDeclineButtonClick() {
			this.$bvModal.show('addcost-reason-modal');
		},

		async onRenderPurchaseOrder() {
			console.log('On render Purchase Order');
			let pdf = await this.$refs.hxPdfExporterPurchaseOrder.getRenderedPDFTemplate();
			let response = await DocumentService.get(this.projectId);
			let documents = response.data;
			let pdfFileParams = {
				content: pdf,
				project_id: this.projectId,
				file_name: `purchase-order-${documents.purchase_order.length}.pdf`,
			};
			let purchaseOrderFileKey = await this._uploadPdf(pdfFileParams);
			documents.purchase_order.push(purchaseOrderFileKey);
			await this._updateEstimateParticular(this.estimateParticularId, {
				purchase_order_file_key: documents.purchase_order,
			});
		},

		async _uploadPdf(params) {
			try {
				let response = await TransferService.postPdf(params);
				if (!response || response.status !== 200) {
					throw 'Upload PDF failed!';
				}

				console.log('%c Upload PDF successfully!', 'color: green');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Update Estimate particular
		 * @param {Integer} estimateParticularId
		 * @param {Object} body update object
		 */
		async _updateEstimateParticular(estimateParticularId, body) {
			try {
				let response = await EstimateParticularService.update(estimateParticularId, {
					doc_content: body,
				});
				if (response && response.status === 200) {
					console.log('%c Update Estimate particular successfully!', 'color: green');
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		async onSendRetryReason(data) {
			this.$store.commit('setIsAppProcessing', true);

			this.plainContents = REASON_SENT_CONTENTS;
			this.$bvModal.show(`${this.plainModalId}`);
			this.showBottomBtn = false;
			this.showStatusBadge = true;
			this.badgeType = 'notify';
			this.badgeContent = '再検討依頼中';
			let updateDetail = {
				reason: data,
				status: CommonConst.SCREEN_STATUS.DECLINE,
			};
			await this._updateAddCostDetails(this.addCostDetailId, updateDetail);

			this.addCostStatus = CommonConst.SCREEN_STATUS.DECLINE;
			this.showBottomBtn = false;

			socketClient.send('addcost_send_fix_required', {
				userId: this.userId,
				projectId: this.projectId,
				content: updateDetail,
			});
			//send mail to creator case 47
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「再提案の詳細」の再検討依頼が届きました`;
			this.mailContent = `「再提案の詳細」の\n再検討依頼が届きました。`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 「再提案の詳細」の再検討依頼が届きました`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 「再提案の詳細」の再検討依頼を送りました`;
			this.mailContent = `「再提案の詳細」の\n再検討依頼を送りました。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「再提案の詳細」の再検討依頼を送りました`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$store.commit('setIsAppProcessing', false);
		},

		async onDownload() {
			this.$store.commit('setIsAppProcessing', true);

			if (!this.pdfFileUrl) {
				this.$store.commit('setIsAppProcessing', false);
				return;
			}
			this._getDefaultFileName();

			// Using Axios get PDF file to browser and rename it before downloading
			// Reason: Can not rename PDF file directly by external URL just using <a> tag
			try {
				let pdfFile = await Axios.get(this.pdfFileUrl, {
					headers: {
						'Content-Type': 'application/octet-stream',
					},
					responseType: 'blob',
				});
				let aTag = this.$refs.downloadTag;
				let url = window.URL.createObjectURL(pdfFile.data);
				aTag.href = url;
				aTag.download = this.defaultFileName;
				aTag.click();
			} catch (error) {
				console.log(error);
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		async onAcceptButtonClick() {
			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		async _getPreviewPDFUrl(key, expiration) {
			try {
				let response = await TransferService.get(key, expiration);
				if (!response || response.status !== 200) {
					throw 'Get PDF url failed!';
				}

				console.log('%c Get PDF url successfully!', 'color: red');
				let url = response.data.link;
				console.log({ url });
				return url;
			} catch (error) {
				console.log(error);
			}
		},

		onPDFClick() {
			if (this.clientMode) return;
			this.pdfPreviewer.url = this.pdfFileUrl;
			this.$bvModal.show(`preview-pdf-modal`);
		},

		/**
		 * Get estimate data
		 * Include: estimateParticular, EstimateDetails and projectName
		 */
		async _getEstimateData() {
			// Check if current project id existed
			if (this.projectId) {
				await this._getEstimateParticularAndChildren(this.projectId);
				await this._getPdfFileUrl(this.pdfFileKey);
			}
		},

		_getDefaultFileName() {
			let nowDate = new Date();
			let downloadDateTime = moment(nowDate).format('YYYYMMDDHHmmss');
			this.defaultFileName =
				this.companyName +
				'様_' +
				this.projectInfo['project_name'] +
				'_見積書_' +
				downloadDateTime +
				'.pdf';
		},

		/**
		 * Format Date string
		 * @param {String} date
		 * @returns Data in Japanese format 2021年9月18日
		 */
		_formatDateString(date) {
			if (date && typeof date === 'string') {
				let momentDate = moment(date);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');

				return `${year}年${month}月${day}日`;
			}
			return date;
		},

		/**
		 * Get pdf template file as url
		 * @param {String} pdfFileKey Key of the PDF template file get from pdf_file_key from record
		 */
		async _getPdfFileUrl(pdfFileKey) {
			if (pdfFileKey) {
				let pdfFileUrl = await this._getPreviewPDFUrl(pdfFileKey, this.expiration);
				this.pdfFileUrl = pdfFileUrl;
			}
		},

		/**
		 * Get Estimate Particular And Children records
		 * @param {integer} projectId : project id
		 */
		async _getEstimateParticularAndChildren(projectId) {
			try {
				let response = await EstimateParticularService.get(projectId);
				if (response && response.status === 200) {
					let data = response.data;
					let estimateDetails = data['estimate_details'];
					this.estimateParticularId = data['estimate_particular']['id'];

					let addCostDetails = await this._getAddCostDetails(this.estimateParticularId);
					this.addCostDetails = addCostDetails;

					this.addCostDetailId = addCostDetails[addCostDetails.length - 1]['id'];

					let lastAddCostItem = addCostDetails[addCostDetails.length - 1];
					if (addCostDetails.length === 0) {
						this.pdfFileKey = data['estimate_particular']['pdf_file_key'];
					} else {
						this.pdfFileKey = data['estimate_particular']['pdf_add_cost_file_key'];
						if (lastAddCostItem['status'] === 'onCreateNew') {
							this.isTemplateShow = false;
						} else {
							this.isTemplateShow = true;
						}
					}
					this.addcostRedeliveryDate = moment(
						lastAddCostItem['redelivery_date']
					).format('YYYY/MM/DD');
					this.addcostReproposalDate = moment(
						lastAddCostItem['reproposal_date']
					).format('YYYY/MM/DD HH:mm');
					this.addcostDetailRemark = lastAddCostItem['remark'];

					const addCostStatus = addCostDetails[addCostDetails.length - 1]['status'];
					this.addCostStatus = addCostStatus;
					this._checkAddCostStatus(addCostStatus);

					let projectName = {
						project_name: this.projectInfo['project_name'],
					};

					this.originEstimateData.estimateParticular = {
						...data['estimate_particular'],
						...projectName,
					};

					//Concat estimateDetails's data and addCostDetails's data
					this.originEstimateData.estimateDetails = await this._filteredAddCostDetails(
						addCostDetails,
						estimateDetails
					);

					this.computeAddCostDetailsMoneyAmount(
						this.managementMasterInfo['consumption_tax_rate'],
						this.addCostDetails
					);

					this.pdfData = { ...this.originEstimateData };
				} else {
					console.log('Get estimate particular failed');
				}
			} catch (error) {
				console.log(`Get estimate particular failed: ${error}`);
			}
		},

		/**
		 * Combined estimate Details and addCost Details
		 * @param {String} quoteId
		 * @param {Array} estimateDetails
		 * @returns Array of estimate Details and addCost Details
		 */
		async _filteredAddCostDetails(addCostDetails, estimateDetails) {
			return estimateDetails.concat(addCostDetails);
		},

		/**
		 * Get data from Add Cost Details Table
		 * @param {String} quoteId
		 * @returns Add Cost Details 's Data
		 */
		async _getAddCostDetails(quoteId) {
			try {
				let response = await AddCostDetailService.get(quoteId);
				if (response && response.status !== 200) {
					throw 'Get add cost detail failed';
				}
				let addCostDetails = response.data;
				return addCostDetails;
			} catch (error) {
				console.log(`Get add_cost detail failed: ${error}`);
			}
		},

		/**
		 * Get data from Add Cost Details Table
		 * @param {String} detailId add cost detail id
		 * @param {Object} addCostDetail add cost detail content
		 * @returns Add Cost Details 's Data
		 */
		async _updateAddCostDetails(detailId, addCostDetail) {
			try {
				let response = await AddCostDetailService.update(detailId, addCostDetail);
				if (response && response.status === 200) {
					console.log('Update Addcost detail successfully!');
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(error);
			}
		},

		_checkAddCostStatus(status) {
			if (status === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.showBottomBtn = true;
				this.isTemplateShow = true;
				this.showStatusBadge = false;
			} else if (status === CommonConst.SCREEN_STATUS.DECLINE) {
				this.showStatusBadge = true;
				this.badgeType = 'notify';
				this.badgeContent = '再検討依頼中';
				this.showBottomBtn = false;
			} else if (status === CommonConst.SCREEN_STATUS.FINISH) {
				this.showBottomBtn = false;
			}
		},
	},

	async created() {

		//When creator click in Save Button
		socketClient.listen('received_new_addcost_quotation', async (data) => {
			if (data) {
				this.$store.commit('setIsAppProcessing', true);

				await this._getEstimateData();
				// this.addCostStatus = CommonConst.SCREEN_STATUS.WAITING_CONFIRM;

				this.$store.commit('setIsAppProcessing', false);
			}
		});

		socketClient.listen('new_data_transfer', (data) => {
			switch (data?.event_name) {
				case 'client-remove-reddot':
					if (!!this.preview || this.clientMode)
						this.removeRedDotNotify(CommonConst.SCREEN_ID.ADDCOST);
					break;

				default:
					break;
			}
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		moment.locale('ja');
		await this._getEstimateData();

		this.$store.commit('setIsAppProcessing', false);
	},

	beforeDestroy() {

		socketClient.removeAllListeners('received_new_addcost_quotation');
	},
};
