import socketClient from '@/services/SOCKET.js';

export default {
	methods: {
		/**
		 * Show red dot notify for sideBarLeft
		 * @param {Integer} screenID
		 */
		setRedDotNotiy(screenID) {
			this.$store.commit('setRedDotIndexs', [screenID]);
		},

		/**
		 * Remove red dot notify
		 * @param {Integer} screenID
		 */
		removeRedDotNotify(screenID) {
			const redDotIndexs = this.redDotIndexs.filter(
				(screenId) => screenId !== screenID
			);
			this.$store.commit('setRedDotIndexs', redDotIndexs);
		},

		/**
		 * Remove red dot notify
		 * @param {Integer} screenID
		 */
		removeRedDotNotifyWithSocket(screenID) {
			const redDotIndexs = this.redDotIndexs.filter(
				(screenId) => screenId !== screenID
			);
			this.$store.commit('setRedDotIndexs', redDotIndexs);

			// emit event to remove red dot notification for readonly and clientMode
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'client-remove-reddot',
				},
				room: this.projectId,
			});
		},
	},
};
